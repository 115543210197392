import { BrowserRouter, Navigate, Route, Routes, useLocation } from 'react-router-dom';
import AuthView from '../Auth/AuthView';
import RegisterInviteView from '../Register/RegisterInviteView';

function ResetPasswordRedirect() {
  const location = useLocation();
  const queryParams = location.search;
  return <Navigate to={`/${queryParams}&state=reset`} />;
}

export default function GuestRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<AuthView />} />
        <Route path='/reset-password' element={<ResetPasswordRedirect />} />
        <Route path='register/:id' element={<RegisterInviteView />} />
        <Route path='*' element={<Navigate to={'/'} />} />
      </Routes>
    </BrowserRouter>
  );
}
