import {
  House,
  Bag,
  Box,
  Calendar,
  PencilSquare,
  Cash,
  Gear,
  Person,
} from 'react-bootstrap-icons';

const iconMap: { [key: string]: JSX.Element } = {
  House: <House />,
  Bag: <Bag />,
  Box: <Box />,
  Calendar: <Calendar />,
  PencilSquare: <PencilSquare />,
  Cash: <Cash />,
  Gear: <Gear />,
  Person: <Person />,
};

export const useIcons = () => {
  const getIcon = (iconName: string) => {
    return iconMap[iconName] || <House />;
  };

  const allIcons = Object.entries(iconMap).map(([key, value]) => ({ key, value }));

  return { getIcon, allIcons };
};
