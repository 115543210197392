import { ConfigProvider, Form, Input, message } from 'antd';
import styles from './index.module.scss';
import authStore from '../../stores/authStore';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useNavigate, useParams } from 'react-router';
import http from '../../services/http';

const RegisterInviteView = observer(() => {
  const [form] = Form.useForm();

  const { id } = useParams();

  const nav = useNavigate();

  const onVerify = async () => {
    if (authStore.isLoading) {
      return;
    }
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      await http.post('/auth/register-employee', { ...values, invitation: id });
      nav('/', { replace: true });
      message.success('Пожалуйста подтвердите свой email');
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            itemMarginBottom: 18,
          },
          Input: {
            lineHeight: 3,
            fontSize: 15,
          },
        },
      }}
    >
      <div className={classNames(styles.area, 'fade-in-opacity')}>
        <div className={styles.form}>
          <Form layout='vertical' form={form}>
            <div className={styles.logo} />
            <h2>Добро пожаловать в Flite CRM</h2>
            <p>Вас пригласили в систему</p>
            <Form.Item name='password' required={false} rules={[{ required: true, message: '' }]}>
              <Input.Password placeholder='Пароль' />
            </Form.Item>
            <Form.Item
              name='passwordConfirmation'
              required={false}
              rules={[{ required: true, message: '' }]}
            >
              <Input.Password placeholder='Повторите пароль' />
            </Form.Item>

            <div onClick={onVerify} className={styles.button}>
              {authStore.isLoading ? 'Пожалуйста подождите' : 'Подтвердить'}
            </div>
          </Form>
        </div>
      </div>
    </ConfigProvider>
  );
});

export default RegisterInviteView;
