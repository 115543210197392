import axios, { AxiosResponse, AxiosError } from 'axios';
import i18n from '../utils/Locale/i18n';
import { Modal } from 'antd';
import AppConsts from '../libs/AppConsts';
import getTimezone from '../utils/getTimezone';

const http = axios.create({
  baseURL: AppConsts.host,
  headers: { 'Content-Type': 'application/json', 'X-CSRF-TOKEN': '' },
});

http.defaults.headers['accept-language'] = i18n.language;

// Request interceptor to add the token to every request
http.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    const selectedLanguage = i18n.language;
    const timezone = getTimezone();

    config.headers['accept-language'] = selectedLanguage;
    config.headers['x-timezone'] = timezone;
    return config;
  },
  (error) => Promise.reject(error)
);

// Response interceptor to handle 401 Unauthorized globally
http.interceptors.response.use(
  (response: AxiosResponse) => response,
  async (error: AxiosError) => {
    if (!error.config) {
      return;
    }
    if (error.response?.status === 400) {
      const data = error.response.data as any;

      // Check if 'messages' exist in the response, containing nested errors
      let text = data.message;

      if (text === 'Unauthenticated.') {
        localStorage.removeItem('token');
        window.location.reload();
        return;
      }

      if (data.messages) {
        text = Object.keys(data.messages)
          .map((field) => {
            // Join messages for each field into a single string
            return `${field}: ${data.messages[field].join(', ')}`;
          })
          .join('\n');
      }

      // Display error with parsed messages in a modal
      Modal.error({
        title: 'Внимание',
        content: text,
        okText: 'Понятно',
        onOk: () => {
          Modal.destroyAll();
        },
      });
    }

    return Promise.reject(error);
  }
);

export default http;
