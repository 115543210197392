import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import SystemRoutes from './views/Navigation/SystemRoutes';
import { observer } from 'mobx-react';
import authStore from './stores/authStore';
import { useEffect, useState } from 'react';
import appConfigStore from './stores/appConfigStore';
import AdminDashboardView from './views/Admin/AdminDashboard';
import GuestRoutes from './views/Navigation/GuestRoutes';

const MyApp = observer(() => {
  if (authStore.isGuest) {
    return <GuestRoutes />;
  }

  return <AppManageStore />;
});

export default MyApp;

const AppManageStore = observer(() => {
  const [views, setViews] = useState<any[]>();

  useEffect(() => {
    const fetchViews = async () => {
      await appConfigStore.getConfig();
      const systemRoutes = await getSystemRoutes();
      setViews(systemRoutes);
    };
    fetchViews();
  }, []);

  if (appConfigStore.isLoading) {
    return <div />;
  }

  if (appConfigStore.isSuperAdmin) {
    return <AdminDashboardView />;
  }

  if (!views) {
    return <div />;
  }

  const router = createBrowserRouter(views);

  return <RouterProvider router={router} />;
});

export const getSystemRoutes = async () => {
  let tabs = SystemRoutes;
  tabs[0].children.forEach((tab, index) => {
    if (!appConfigStore.config?.menuItems.find((e) => e.route.url === tab.path)) {
      if (appConfigStore.isOwner && tab.path === 'settings') {
        //
      } else {
        tabs[0].children.splice(index, 1);
      }
    }
  });

  return tabs;
};
