import { Col, ConfigProvider, Form, Input, message, Row, Select, Button } from 'antd';
import styles from './index.module.scss';
import authStore from '../../stores/authStore';
import { observer } from 'mobx-react';
import classNames from 'classnames';
import { useLocation, useNavigate } from 'react-router';
import { useEffect } from 'react';
import { useWindowSize } from '@uidotdev/usehooks';

const AuthView = observer(() => {
  const [form] = Form.useForm();

  const size = useWindowSize();
  const location = useLocation();
 
  const queryParameters = new URLSearchParams(location.search);

  const invitation = queryParameters.get('invitation');

  const state = queryParameters.get('state');

  const email = queryParameters.get('email');

  const token = queryParameters.get('token');

  const nav = useNavigate();

  useEffect(() => {
    if (invitation) {
      nav(`/register/${invitation}`, { replace: true });
    }
    if (state === 'register') {
      onRegisterState();
    }
    if (state === 'forgot') {
      onForgotState();
    }
    if (state === 'reset') {
      onResetState();
    }
  }, [invitation]);

  const isMobile = size.width && size.width < 600;

  const onConfirm = async () => {
    if (authStore.isLoading) {
      return;
    }
    try {
      await form.validateFields();
      const values = form.getFieldsValue();
      if (authStore.isLogin) {
        await authStore.login(values);
        form.resetFields();
      }
      if (authStore.isRegister) {
        await authStore.register(values);
        form.resetFields();
        message.success('Пожалуйста подтвердите свой Email через свой электронный ящик');
      }
      if(authStore.isForgot){
        const response = await authStore.forgotPassword(values);
        console.log(response);
        form.resetFields();
        message.success(response?.message);
        onLoginState();
      }
      if(authStore.isReset && token){
        const response = await authStore.resetPassword({
          email: values.email, 
          password: values.password, 
          password_confirmation: values.passwordConfirmation,
          token, 
        });
        form.resetFields();
        message.success(response?.message);
        onLoginState();
      }
    } catch (e) {
      console.log(e);
    }
  };

  const onRegisterState = async () => {
    nav('?state=register');
    authStore.entry = 'Register';
    await authStore.onFetchLookups();
    form.resetFields();
  };

  const onForgotState = async () => {
    if(state !== 'forgot') {
      nav('?state=forgot');
    }
    authStore.entry = 'Forgot';
    form.resetFields();
  };

  const onResetState = async () => {
    if(state !== 'reset') {
      nav('?state=reset');
    }
    authStore.entry = 'Reset';
    form.resetFields();
    if(email && token) {
      form.setFieldValue('email', email);
    } else {
      message.error('Неверная ссылка', 100);
      onLoginState();
    }
  };

  const onLoginState = () => {
    nav('/', { replace: true });
    form.resetFields();
    authStore.entry = 'Password';
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Form: {
            itemMarginBottom: 16,
          },
          Input: {
            controlHeight: state === 'register' ? 37 : 40,
          },
          InputNumber: {
            controlHeight: state === 'register' ? 37 : 40,
          },
          Select: {
            controlHeight: state === 'register' ? 37 : 40,
          },
        },
      }}
    >
      <div className={classNames(styles.area, 'fade-in-opacity')}>
        <div className={styles.cover}>
          <div className={styles.form} style={{ width: state === 'register' ? 490 : 'auto' }}>
            <Form layout='vertical' form={form}>
              <div className={styles.logo} />
              <h2>Добро пожаловать в Flite CRM</h2>
              {authStore.isLogin && <p>Пожалуйста войдите в свою учетную запись</p>}
              {authStore.isRegister && <p>Создайте свою новую учетную запись</p>}
              {authStore.isForgot && <p>Пожалуйста введите свой Email</p>}
              {authStore.isReset && <p>Пожалуйста введите новый пароль</p>}
              {authStore.isRegister && (
                <Row gutter={12}>
                  <Col span={isMobile ? 24 : 12}>
                    <Form.Item name='firstName' rules={[{ required: true, message: '' }]}>
                      <Input placeholder='Имя' />
                    </Form.Item>
                  </Col>
                  <Col span={isMobile ? 24 : 12}>
                    <Form.Item name='lastName' rules={[{ required: true, message: '' }]}>
                      <Input placeholder='Фамилия' />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              {authStore.isRegister && (
                <Row gutter={12}>
                  <Col span={isMobile ? 24 : 12}>
                    <Form.Item name='domainIds' rules={[{ required: true, message: '' }]}>
                      <Select mode='multiple' placeholder='Деятельность'>
                        {authStore.lookups?.domains.map((e) => (
                          <Select.Option key={e.id} value={e.id}>
                            {e.title}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={isMobile ? 24 : 12}>
                    <Form.Item name='phone' rules={[{ required: true, message: '' }]}>
                      <Input placeholder='Телефон' type='tel' />
                    </Form.Item>
                  </Col>
                </Row>
              )}

              <Form.Item name='email' rules={[{ required: true, message: '' }]}>
                <Input disabled={authStore.isReset} placeholder='Email' />
              </Form.Item>
              
              {!authStore.isForgot && (
              <Form.Item name='password' rules={[{ required: true, message: '' }]}>
                <Col>
                  <Input.Password placeholder='Пароль' />
                  {authStore.isLogin && (
                    <Row justify='end'>
                      <Button color="default" type='link' htmlType='button' className={styles.forgotPassword} onClick={onForgotState}>
                        Забыли пароль?
                      </Button>
                    </Row>
                  )}
                </Col>
              </Form.Item>
              )}

              {(authStore.isRegister || authStore.isReset) && (
                <Form.Item name='passwordConfirmation' rules={[{ required: true, message: '' }]}>
                  <Input.Password placeholder='Повторите пароль' />
                </Form.Item>
              )}

              {authStore.isRegister && (
                <div onClick={onConfirm} className={styles.button}>
                  Подтвердить
                </div>
              )}

              {authStore.isLogin && (
                <div onClick={onConfirm} className={styles.button}>
                  {authStore.isLoading ? 'Пожалуйста подождите' : 'Войти'}
                </div>
              )}

              {authStore.isForgot && (
                <div onClick={onConfirm} className={styles.button}>
                  {authStore.isLoading ? 'Пожалуйста подождите' : 'Восстановить'}
                </div>
              )}

              {authStore.isReset && (
                <div onClick={onConfirm} className={styles.button}>
                  {authStore.isLoading ? 'Пожалуйста подождите' : 'Изменить'}
                </div>
              )}

              {(authStore.isRegister || authStore.isForgot) && (
                <div className={styles.createAccount} onClick={onLoginState}>
                  Войти в учетную запись
                </div>
              )}
              {authStore.isLogin && (
                <div className={styles.createAccount} onClick={onRegisterState}>
                  Создать учетную запись
                </div>
              )}
            </Form>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
});

export default AuthView;
