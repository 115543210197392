import { Suspense, lazy } from 'react';
import { Navigate } from 'react-router';
const DashboardView = lazy(() => import('../Dashboard/DashboardView'));
const HomeView = lazy(() => import('../Home/HomeView'));
const ProfileView = lazy(() => import('../Profile/ProfileView'));
const CalendarView = lazy(() => import('../Planner/PlannerView'));
const SettingsView = lazy(() => import('../Settings/SettingsView'));
const LeadsView = lazy(() => import('../Leads/LeadsView'));
const LeadView = lazy(() => import('../Lead/LeadView'));
const ReportsView = lazy(() => import('../Reports/ReportsView'));
const ClientsView = lazy(() => import('../Clients/ClientsView'));
const SupportView = lazy(() => import('../Support/SupportView'));
const MeasurerView = lazy(() => import('../Measurer/MeasurerView'));
const MeasureLeadView = lazy(() => import('../Measurer/views/MeasureLead/MeasureLeadView'));

const FinancesView = lazy(() => import('../Finances/FinancesView'));
const FinancesDirectoryView = lazy(
  () => import('../Finances/views/FinancesDirectory/FinancesDirectoryView')
);

const BasicSettingsView = lazy(() => import('../Settings/views/Basic/BasicSettingsView'));
const DirectoriesView = lazy(() => import('../Settings/views/Directories/DirectoriesView'));
const GoodsView = lazy(() => import('../Settings/views/Goods/GoodsCategoriesView'));
const UsersView = lazy(() => import('../Settings/views/Users/UsersView'));
const CanvasView = lazy(() => import('../Settings/views/Canvas/CanvasView'));
const TasksView = lazy(() => import('../Tasks/TasksView'));
const EditorView = lazy(() => import('../Painter/PainterView'));
const SpaceView = lazy(() => import('../Lead/views/Spaces/views/SpaceView/SpaceView'));

const SystemRoutes = [
  {
    path: '/',
    element: <Wrap view={<DashboardView />} />,
    children: [
      { path: 'home', element: <Wrap view={<HomeView />} /> },
      {
        path: 'measure',
        children: [
          { index: true, element: <Wrap view={<MeasurerView />} /> },
          { path: ':id', element: <Wrap view={<MeasureLeadView />} /> },
        ],
      },
      { path: 'profile', element: <Wrap view={<ProfileView />} /> },
      { path: 'calendar', element: <Wrap view={<CalendarView />} /> },
      { path: 'editor/:id', element: <Wrap view={<EditorView />} /> },
      { path: 'space/:id', element: <Wrap view={<SpaceView />} /> },
      {
        path: 'finances',
        element: <Wrap view={<FinancesView />} />,
        children: [
          { path: 'income', element: <Wrap view={<div />} /> },
          { path: 'spending', element: <Wrap view={<div />} /> },
          { path: 'report', element: <Wrap view={<div />} /> },
          { path: 'directory', element: <Wrap view={<FinancesDirectoryView />} /> },
        ],
      },
      {
        path: 'settings',
        element: <Wrap view={<SettingsView />} />,
        children: [
          { path: 'basic', element: <Wrap view={<BasicSettingsView />} /> },
          { path: 'directory', element: <Wrap view={<DirectoriesView />} /> },
          { path: 'products', element: <Wrap view={<GoodsView />} /> },
          { path: 'license', element: <Wrap view={<div />} /> },
          { path: 'users', element: <Wrap view={<UsersView />} /> },
          { path: 'canvas', element: <Wrap view={<CanvasView />} /> },
        ],
      },
      { path: 'clients', element: <Wrap view={<ClientsView />} /> },
      { path: 'support', element: <Wrap view={<SupportView />} /> },
      {
        path: 'leads',
        children: [
          { index: true, element: <Wrap view={<LeadsView />} /> },
          { path: ':id', element: <Wrap view={<LeadView />} /> },
        ],
      },
      { path: 'reports', element: <Wrap view={<ReportsView />} /> },
      { path: 'tasks', element: <Wrap view={<TasksView />} /> },
      { path: '*', element: <Wrap view={<Navigate to={'/'} />} /> },
    ],
  },
];

export default SystemRoutes;

function Wrap({ view }: { view: React.ReactNode }) {
  return <Suspense fallback={<div />}>{view}</Suspense>;
}
