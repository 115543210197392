import { makeAutoObservable } from 'mobx';
import http from '../services/http';
import AppConsts from '../libs/AppConsts';

class AppConfigStore {
  config?: IConfig;
  isLoading = false;

  get currency() {
    return this.config?.currency.title;
  }

  get isOwner() {
    return this.config?.role.name === 'owner';
  }

  get isInstaller() {
    return this.config?.role.name === 'installer';
  }

  get isSuperAdmin() {
    return this.config?.role.name === AppConsts.hostAdmin;
  }

  async getConfig() {
    this.isLoading = true;
    try {
      const result = await http.get('/app-config');
      this.config = result.data;
    } finally {
      this.isLoading = false;
    }
  }
  
  async onRefreshConfig() {
    const result = await http.get('/app-config');
    this.config = result.data;
  }

  constructor() {
    makeAutoObservable(this);
  }
}

const appConfigStore = new AppConfigStore();

export default appConfigStore;

interface IConfig {
  currency: {
    value: string;
    title: string;
  };
  language: string;
  role: {
    name: string;
    label: string;
  };
  menuItems: IMenuItem[];
  license?: {
    name: string;
    expirationDate: Date;
  };
}

export interface IMenuItem {
  name: string;
  icon: string;
  route: {
    name: string;
    url: string;
  };
}
